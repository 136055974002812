import { StyledBody } from 'baseui/card';
import * as React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { ALIGN, StyledNavigationItem, StyledNavigationList } from 'baseui/header-navigation';
import Link from 'common/link';

const ProjectsPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <StyledBody>
      <StyledNavigationList $align={ALIGN.center}>
      <StyledNavigationItem>
        <Link href="/projects/">PROJECTS</Link>
      </StyledNavigationItem>
      <StyledNavigationItem>
              <Link href="/press/">PRESS</Link>
      </StyledNavigationItem>
        </StyledNavigationList>
        <div align="center">
            Residential | Commercial
        </div>
      </StyledBody>
    </Layout>
  );
};


export default ProjectsPage;